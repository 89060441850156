import React, { useCallback } from 'react'
import { CircleCloseIcon } from '@/atoms/Icons/CircleCloseIcon'
import { EmbeddedVideo } from '@/molecules/EmbeddedVideo'
import { Modal } from '@/molecules/Modal/Modal'

export interface YoutubeVideoModalProps {
  youTubeUrl?: string | null
  handleClose: () => void
  showModal: boolean
  className?: string
}

export const YoutubeVideoModal: React.FC<YoutubeVideoModalProps> = ({
  youTubeUrl,
  handleClose,
  showModal,
  className,
}) => {
  const handleModalClose = useCallback(() => {
    handleClose()
  }, [handleClose])

  return (
    <Modal
      isOpen={showModal}
      onClose={handleModalClose}
      panelClasses="min-w-[80vw] lg:min-w-[55vw] !bg-transparent !p-0 !shadow-none"
      className={className}
    >
      <div className="relative overflow-hidden rounded-xl">
        <EmbeddedVideo url={`${youTubeUrl}?autoplay=1`} className="w-full" />
        <div
          className="absolute right-2 top-2 z-[10000000] h-8 w-8 cursor-pointer rounded-full bg-transparent transition-all duration-300 ease-in-out hover:opacity-80 md:right-4 md:top-6"
          onClick={handleModalClose}
        >
          <CircleCloseIcon color="core-gray-100" size={32} className="cursor-pointer" />
        </div>
      </div>
    </Modal>
  )
}

import React from 'react'
import { useQuery } from '@apollo/client'
import { getViewerRegionQuery } from '@/services/UserRegionService'
import { getRegionWithOverrides } from '@/services/UserRegionService/UserRegionService'
import { GetViewerRegionQuery } from '@/types/codegen-federation'
import { ReactFCC } from '@/types/react'

interface RegionContextProp {
  children: React.ReactNode
}

interface RegionContextType {
  region: string
  loading: boolean
}

const RegionContext = React.createContext<RegionContextType>({
  region: 'US',
  loading: true,
})

export const RegionContextProvider: ReactFCC<RegionContextProp> = ({ children }) => {
  const { data, loading } = useQuery<GetViewerRegionQuery>(getViewerRegionQuery, {
    errorPolicy: 'all',
  })

  const value = {
    region: getRegionWithOverrides(data?.viewer?.region),
    loading,
  }

  return <RegionContext.Provider value={value}>{children}</RegionContext.Provider>
}

export function useRegionContext() {
  return React.useContext(RegionContext)
}
